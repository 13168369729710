import { useEffect, useState } from 'react'
import {
  SoleFirstView,
  SoleFaq,
  SoleMessage,
  SoleMerit,
  SoleFeatures,
  SolePlan,
  SoleExample,
  SoleFlow,
  SoleVoice,
  GoTop,
} from '~/components'
import * as s from '~/components/pages/sole/sole.module.scss'

export default function SolePage() {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
  }, [])

  return (
    <>
      <SoleFirstView />
      <div
        className={`
        ${s.sole_main_wrapper}
        ${loaded ? s.fadeIn : ''}
      `}
      >
        <SoleMerit />
        <SoleFeatures />
        <SolePlan />
        <SoleExample />
        <SoleVoice />
        <SoleFlow />
        <SoleFaq />
        <SoleMessage />
        <GoTop />
      </div>
    </>
  )
}
